<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background:new 0 0 511.999 511.999;"
    xml:space="preserve"
  >
    <path
      style="fill:#F64B4A;"
      d="M442.907,120.829l-9.7,5.23L73.597,320.028l-24.97-4.961l-20.734-4.13
	c-18.769-3.744-30.961-21.975-27.217-40.743l22.15-111.219l14.65-73.576l3.253-16.346c3.744-18.769,21.986-30.961,40.755-27.217
	l323.254,64.368l11.139,2.223l8.05,1.603C431.569,111.55,438.098,115.458,442.907,120.829z"
    />
    <polygon
      style="fill:#65B4BB;"
      points="326.399,142.944 211.939,196.64 110.502,176.432 22.826,158.974 37.476,85.409 
	283.889,134.484 "
    />
    <path
      style="fill:#E84242;"
      d="M433.207,126.059L73.597,320.028l-24.97-4.961l-21.015-86.881
	c-4.002-16.545,6.155-33.184,22.7-37.186l346.855-83.932c2.539-0.608,5.078-0.889,7.571-0.866c3.873,0.035,7.641,0.819,11.139,2.223
	C423.67,111.55,430.082,117.821,433.207,126.059z"
    />
    <polygon
      style="fill:#57A3A7;"
      points="326.399,142.944 211.939,196.64 110.502,176.432 283.889,134.484 "
    />
    <path
      style="fill:#FED159;"
      d="M141.569,469.969l346.861-83.924c16.537-4.001,26.698-20.65,22.698-37.187l-50.036-206.803
	c-4.001-16.537-20.65-26.698-37.187-22.698L77.043,203.281c-16.537,4.001-26.698,20.65-22.698,37.187l50.036,206.803
	C108.383,463.807,125.033,473.97,141.569,469.969z"
    />
    <polyline
      style="fill:#65B4BB;"
      points="483.553,234.904 76.811,333.316 59.171,260.405 465.911,161.993 "
    />
    <g>
      <rect
        x="351.508"
        y="292.604"
        transform="matrix(0.9719 -0.2352 0.2352 0.9719 -59.292 105.6236)"
        style="fill:#F6C454;"
        width="123.301"
        height="17.553"
      />

      <rect
        x="417.255"
        y="323.777"
        transform="matrix(0.9719 -0.2352 0.2352 0.9719 -65.5999 115.0793)"
        style="fill:#F6C454;"
        width="64.781"
        height="17.553"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
